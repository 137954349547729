var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    attrs: {
      "table-id": "seller_products",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "p_id"
    },
    on: {
      "selectAction": $event => $event.addToList({
        entity: 'product',
        mp: this.reportSettings.mp
      }),
      "selectActionM": $event => $event.addToListM({
        entity: 'product',
        mp: this.reportSettings.mp
      })
    },
    scopedSlots: _vm._u([{
      key: "category",
      fn: function ({
        item,
        categories
      }) {
        return [_c('product-categories', {
          attrs: {
            "product-categories": item.categories_list,
            "base-categories": categories,
            "route-query": {
              date: _vm.reportSettings.date,
              date2: _vm.reportSettings.date2,
              fbs: _vm.reportSettings.fbs
            }
          }
        })];
      }
    }])
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };