import { dateFormat } from "@/utils/dates";
import ReportMixin from "@/mixins/Report";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import SellerProductsTable from "@/components/Seller/ProductsTable.vue";
import SellerBrandsTable from "@/components/Seller/BrandsTable.vue";
import SellerCategoriesTable from "@/components/Seller/CategoriesTable.vue";
import SellerSegmentsTable from "@/components/Seller/SegmentsTable.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import ListAddItemButton from "@/components/List/AddItemButton.vue";
import SwitchInput from "@/components/SwitchInput.vue";
const tableComponents = {
  products: SellerProductsTable,
  brands: SellerBrandsTable,
  categories: SellerCategoriesTable,
  segments: SellerSegmentsTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$seller;
    const name = (_this$seller = this.seller) === null || _this$seller === void 0 ? void 0 : _this$seller.seller;
    return {
      title: name ? `Обзор показателей продавца ${name}` : undefined
    };
  },
  data() {
    let cells = getEntityCellsConfig("seller");
    const customCells = {
      avgPerDay: {
        title: "Среднее<br>продаж в день",
        multi: [{
          id: "avg_sold",
          unit: cells.avg_sold.unit
        }, {
          id: "avg_revenue",
          unit: cells.avg_revenue.unit
        }],
        color: cells.avg_sold.color
      }
    };
    delete cells.avg_sold;
    delete cells.avg_revenue;
    cells = {
      ...cells,
      ...customCells
    };
    return {
      seller: null,
      tabs_tables: [{
        id: "products",
        title: "Товары"
      }, {
        id: "brands",
        title: "Бренды"
      }, {
        id: "categories",
        title: "Категории"
      }
      /* //&: 2022-02-14 : api_CH пока не реализован
              {
                  id: 'segments',
                  title: 'Ценовая сегментация'
              },
              */],
      tabs_tables_model: null,
      cells,
      NA: "н/д"
    };
  },
  methods: {
    dateFormat(v) {
      return dateFormat(v);
    },
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    async loadSeller() {
      this.seller = await this.$store.dispatch("seller/getSeller", {
        ...this.reportSettings
      });
    },
    loadOverview() {
      return this.$store.dispatch("seller/sellerOverviewAll", this.reportSettings);
    },
    href_s_id_BZ(seller) {
      let href = "/" + (this.reportSettings.mp == "wb" ? "ozon" : "wb") + "/seller/" + seller.s_id_BZ + "/info";
      return href;
    }
  },
  async mounted() {},
  computed: {
    track() {
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / Анализ продавца / ${this.tabs_tables_model.title}`,
        name: this.seller.seller,
        date: this.reportSettings.date,
        date2: this.reportSettings.date2
      };
    },
    fileName() {
      return `${this.$route.params.mp.toUpperCase()} Продавец ${this.seller.seller} ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      return {
        mp: this.$route.params.mp,
        s_id: this.$route.params.s_id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        ...this.$route.query
      };
    },
    sellerKey() {
      return `${this.$route.params.s_id}:${this.$route.params.mp}`;
    },
    tableKey() {
      return `${this.sellerKey}:${this.tabs_tables_model.id}`;
    }
  },
  watch: {
    reportSettings: {
      handler(v) {
        if (!v) {
          return;
        }
        this.loadSeller();
      },
      immediate: true
    }
  },
  components: {
    MainTitle,
    AppTabs,
    DataTableActions,
    SellerProductsTable,
    EntityCharts,
    ReportPage,
    ListAddItemButton,
    SwitchInput
  }
};